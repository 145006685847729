import { FuseConfig } from '@fuse/types';

export const fuseConfig: FuseConfig = {
  colorTheme: 'theme-default',
	customScrollbars: true,
	banner: '',
	bgPage: '',
	bgMain: '',
	directorLogo: '',
	hqLogo: '',
	smallLogo: '',
    layout: {
        style: 'horizontal-layout-1',
        width: 'fullwidth',
        navbar: {
            primaryBackground: 'fuse-black-500',
            secondaryBackground: 'fuse-white-500',
			customPrimaryBackground: '',
			customPrimaryForeground: '',
			customSecondaryBackground: '',
			customSecondaryForeground: '',
            folded: false,
            hidden: false,
            position: 'top',
            variant: 'vertical-style-1'
        },
		toolbar: {
			toolBarLogo: '',
            customBackgroundColor: true,
            background: 'dark-orange-500',
			customToolbarBackground: '',
          	customToolbarForeground: '',
            hidden: false,
            position: 'above'
        },
        footer: {
            customBackgroundColor: true,
            background: 'fuse-black-500',
			customFooterBackground: '',
			customFooterForeground: '',
            hidden: false,
            position: 'above-static',
			email: 'info@playonenation.com',
			facebook: 'https://www.facebook.com/themovementsince2012',
			instagram: 'https://www.instagram.com/one_nation_slowpitch/',
			twitter: 'https://twitter.com/GSLSoftball',
			youtube: '',
			linkedin: '',
			forum: ''
        },
        sidepanel: {
            hidden: false,
            position: 'right'
        },
        pageheader: {
            primaryBackground: 'fuse-dark-gray-500',
			customHeaderPrimaryBackground: '',
			customHeaderPrimaryForeground: ''
        },
        linkActiveColor: {
            primaryBackground: 'dark-orange-fg',
            customLinkBackground: '',
            customLinkForeground: ''
        }
  }
};
